import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import useStyles from "./rules.style";
import currency from "./currency.json";
import Form from "react-bootstrap/Form";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateEventRulesAPI } from "../../../../../api/updateEventRulesAPI";
import getEventDetails from "../../../../../api/getEventDetail";
import routes from "../../../../../constants/routes";
import lodash, { isEmpty, omit } from "lodash";
import SelectOrAddCurrency from "../../../SelectOrAddCurrency";

function Rules(props) {
  const classes = useStyles();

  // @coder-kabir -----------------------------------------------------------------------------------------

  const navigate = useNavigate();

  const location = useLocation();
  const eventId = location.state.data.eventId;

  const [eventItemRules, setEventItemRules] = useState({
    previewPeriod: "NO",
    overTimePeriodInMinutes: "",
    publishDateTime: moment().utc().toISOString(),
    bidStartDateTime: moment().utc().toISOString(),
    bidEndDateTime: moment().utc().toISOString(),
    overTimePeriodInMinutes: 0,
    extendWithinBidEndMinutes: 0,
    currency: "",
    showH1L1Bid: "YES",
    showRankTill: "SHOW_TILL_1",
    eventMessage: "",
    incrementBidAmountBy: "VALUE",
    variableIncrementDecrement: "YES",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [change, setChange] = useState(false);

  const [currencyData, setCurrencyData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    eventItemRules.currency
  );

  const handleCurrencySelect = (selectedOption) => {
    setChange(true);
    console.log("selectedOption", selectedOption.value);
    setSelectedCurrency(selectedOption.value);
  };

  useEffect(() => {}, [selectedCurrency]);

  useEffect(() => {
    const currencyArray = Object.entries(currency[0]).map(([key, value]) => ({
      value: key,
      label: value.code,
    }));
    setCurrencyData(currencyArray);
  }, []);

  const [errors, setErrors] = useState({});
  const validate = (name, value) => {
    //A function to validate each input values
    switch (name) {
      case "publishDateTime":
        if (value === null) {
          // we will set the error publishDateTime

          setErrors({
            ...errors,
            publishDateTime: "Publish Date Time Can Not Be Empty.",
          });
        } else {
          // set the error publishDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "publishDateTime");
          setErrors(newObj);
        }
        break;

      case "bidStartDateTime":
        if (value === null) {
          // we will set the error bidStartDateTime

          setErrors({
            ...errors,
            bidStartDateTime: "Bid Start Date Time Can Not Be Empty.",
          });
        } else {
          // set the error bidStartDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "bidStartDateTime");
          setErrors(newObj);
        }
        break;

      case "bidEndDateTime":
        if (value === null) {
          // we will set the error bidEndDateTime

          setErrors({
            ...errors,
            bidEndDateTime: "Bid End Date Time Can Not Be Empty.",
          });
        } else {
          // set the error bidEndDateTime empty or remove the error for companyName input

          //omit function removes/omits the value from given object and returns a new object
          let newObj = omit(errors, "bidEndDateTime");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  };

  const handleChange = (event) => {
    setEventItemRules({
      ...eventItemRules,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currencyToSubmit = selectedCurrency || eventItemRules.currency;
    if (!isEmpty(errors)) {
      toast("Please Provide Correct Values!");
    } else {
      try {
        event.preventDefault();

        const payload = {
          ...eventItemRules,
          currency: currencyToSubmit,
          showH1L1Bid: eventItemRules.showH1L1Bid,
          showRankTill:
            eventItemRules.showH1L1Bid === "YES"
              ? eventItemRules.showRankTill
              : null,
        };
        const updateResponse = await updateEventRulesAPI(payload, eventId);

        if (updateResponse.status === 200 || updateResponse.status === 201) {
          toast("Event Rules Create Successfully!");
          setTimeout(() => {
            props.a11yProps(1);
          }, 1500);
        } else {
          throw new Error("Something went wrong");
        }
      } catch (error) {
        if (error.isAxiosError) {
          console.error("Axios error:", error.message);
          console.error("Status:", error.response.status);
          toast(error.response.data?.error);
          setIsLoading(false);
        } else if (error.response.status === 500) {
          toast("Network Error!");
          setIsLoading(false);
        } else if (error.request) {
          toast("Something went wrong!");
          setIsLoading(false);
        } else {
          console.log("Error", error);
          setIsLoading(false);
        }
      }
    }
  };

  const eventDetails = async () => {
    try {
      const eventDetails = await getEventDetails({ auctionId: eventId });

      if (eventDetails.status === 200) {
        setEventItemRules({
          previewPeriod: eventDetails.data.previewPeriod,
          overTimePeriodInMinutes: eventDetails.data.overTimePeriodInMinutes,
          publishDateTime: moment(eventDetails.data.publishDateTime),
          bidStartDateTime: moment(eventDetails.data.bidStartDateTime),
          bidEndDateTime: moment(eventDetails.data.bidEndDateTime),
          overTimePeriodInMinutes: eventDetails.data.overTimePeriodInMinutes,
          extendWithinBidEndMinutes:
            eventDetails.data.extendWithinBidEndMinutes,
          currency: eventDetails.data.currency,
          showH1L1Bid: String(eventDetails.data.showH1L1Bid),
          showRankTill: eventDetails.data.showRankTill,
          eventMessage: eventDetails.data.eventMessage,
          incrementBidAmountBy: eventDetails.data.incrementBidAmountBy,
          variableIncrementDecrement:
            eventDetails.data.variableIncrementDecrement || "YES",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    eventDetails();
    return () => null;
  }, [eventId]);

  const dateHandler = (name, date) => {
    validate(name, date);
    if (lodash.isEmpty(date)) {
      return true;
    }

    if (date.$d == "Invalid Date") {
      return true;
    }

    handleChange({
      target: { name, value: moment(date.$d).utc().toISOString() },
    });
  };

  const handleCreateNewOption = (inputValue) => {
    const newOption = {
      label: inputValue.toUpperCase(), // Assuming you want the currency codes to be uppercase
      value: inputValue,
    };
    // Update your state here, for example:
    setCurrencyData([...currencyData, newOption]);
    // Optionally, you can also set this new option as the selected option
    setSelectedCurrency(inputValue);
  };

  const isValidCurrencyValue = currencyData.some(
    (option) => option.value === eventItemRules.currency
  );

  // Set a default value if the selectedCurrencyValue is not valid
  const defaultCurrency = isValidCurrencyValue
    ? eventItemRules.currency
    : currencyData;

  // ------------------------------------------------------------------------------------------------------

  return (
    <div className={classes.rules}>
      <form onSubmit={handleSubmit}>
        <Box className={classes.accordanContent}>
          <div className={classes.formHead}>
            <h5 className="head1">Timing Rules</h5>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <Form.Label className={classes.label}>
                  Can Participants place bids during preview period
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className={classes.input}
                  name="previewPeriod"
                  value={eventItemRules.previewPeriod}
                  onChange={handleChange}
                >
                  <option value="YES">Yes (Ignore the start price)</option>
                  <option value="NO">No</option>
                  <option value="PREBID_ABOVE_START_PRICE">
                    YES (Consider the start price)
                  </option>
                </Form.Select>
              </div>

              <Box sx={{ marginBottom: "1em" }}>
                <Form.Label className={classes.label}>
                  Bidding start date and time
                </Form.Label>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="datePicker"
                    renderInput={(props) => <TextField {...props} />}
                    value={eventItemRules.bidStartDateTime}
                    onChange={(date) => {
                      dateHandler("bidStartDateTime", date);
                    }}
                    minDate={moment()}
                  />
                </LocalizationProvider>
                {errors.bidStartDateTime && (
                  <span>{errors.bidStartDateTime}</span>
                )}
              </Box>
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Overtime period (Minutes)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    name="overTimePeriodInMinutes"
                    onChange={handleChange}
                    className={classes.input}
                    value={eventItemRules.overTimePeriodInMinutes}
                  />
                </Form.Group>
              </Box>
            </div>
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Auction Publish Date and Time
                  </Form.Label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      className="datePicker"
                      renderInput={(props) => <TextField {...props} />}
                      value={eventItemRules.publishDateTime}
                      onChange={(date) => {
                        dateHandler("publishDateTime", date);
                      }}
                      minDate={moment()}
                    />
                  </LocalizationProvider>
                  {errors.publishDateTime && (
                    <span>{errors.publishDateTime}</span>
                  )}
                </Form.Group>
              </Box>

              <Box sx={{ marginBottom: "1em" }}>
                <Form.Label className={classes.label}>
                  Bidding end date and time
                </Form.Label>{" "}
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    className="datePicker"
                    renderInput={(props) => <TextField {...props} />}
                    value={eventItemRules.bidEndDateTime}
                    onChange={(date) => {
                      dateHandler("bidEndDateTime", date);
                    }}
                    minDate={moment()}
                  />
                </LocalizationProvider>
                {errors.bidEndDateTime && <span>{errors.bidEndDateTime}</span>}
              </Box>
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Bid End Minutes
                  </Form.Label>
                  <Form.Control
                    className={classes.input}
                    type="number"
                    name="extendWithinBidEndMinutes"
                    onChange={handleChange}
                    min="0"
                    value={eventItemRules.extendWithinBidEndMinutes}
                  />
                </Form.Group>
              </Box>
            </div>
          </div>

          <div className={classes.formHead}>
            <h5 className="heading1">Bidding Rules</h5>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Increment bid amount by
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    className={classes.input}
                    name="incrementBidAmountBy"
                    onChange={handleChange}
                    value={eventItemRules.incrementBidAmountBy}
                  >
                    <option disabled>Value/Percentage</option>
                    <option value="VALUE">Value</option>
                    <option value="PERCENTAGE" disabled>
                      Percentage
                    </option>
                  </Form.Select>
                </Form.Group>
              </Box>
            </div>
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Allow variable increment/decrement
                  </Form.Label>
                  <Box sx={{ display: "flex" }} className="mt-1">
                    <Form.Group className="">
                      <Form.Check
                        style={{ marginRight: "4px" }}
                        checked={
                          eventItemRules.variableIncrementDecrement === "YES"
                        }
                        name="variableIncrementDecrement"
                        onChange={handleChange}
                        value="YES"
                        type="radio"
                        label="Yes"
                      />
                    </Form.Group>
                    <Form.Group
                      className={classes.radio}
                      style={{
                        marginLeft: ".6rem",
                      }}
                    >
                      <Form.Check
                        checked={
                          eventItemRules.variableIncrementDecrement === "NO"
                        }
                        name="variableIncrementDecrement"
                        onChange={handleChange}
                        value="NO"
                        type="radio"
                        label="No"
                      />
                    </Form.Group>
                  </Box>
                </Form.Group>
              </Box>
            </div>
          </div>

          <div className={classes.formHead}>
            <h5 className="time1">Currency Rules</h5>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Allow participants to select bidding currency
                  </Form.Label>
                  <SelectOrAddCurrency
                    value={selectedCurrency}
                    setValue={setSelectedCurrency}
                    options={currencyData}
                    setOptions={setCurrencyData}
                  />
                </Form.Group>
              </Box>
            </div>
          </div>

          <div className={classes.formHead}>
            <h5 className="market">Market Feedback</h5>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group>
                  <Form.Label className={classes.label}>
                    Show H1/L1 Bid
                  </Form.Label>
                  <Box sx={{ display: "flex" }}>
                    <Form.Group className="">
                      <Form.Check
                        style={{ marginRight: "4px" }}
                        checked={eventItemRules.showH1L1Bid === "YES"}
                        name="showH1L1Bid"
                        onChange={handleChange}
                        value="YES"
                        type="radio"
                        label="Yes"
                      />
                    </Form.Group>
                    <Form.Group
                      className={classes.radio}
                      style={{
                        marginLeft: ".6rem",
                      }}
                    >
                      <Form.Check
                        checked={eventItemRules.showH1L1Bid === "NO"}
                        name="showH1L1Bid"
                        onChange={handleChange}
                        value="NO"
                        type="radio"
                        label="No"
                      />
                    </Form.Group>
                    <Form.Group
                      className={classes.radio}
                      style={{
                        marginLeft: ".6rem",
                      }}
                    >
                      <Form.Check
                        checked={
                          eventItemRules.showH1L1Bid === "IN_TOP_RANKS_ONLY"
                        }
                        name="showH1L1Bid"
                        onChange={handleChange}
                        value="IN_TOP_RANKS_ONLY"
                        type="radio"
                        label="Show top bid rank only"
                      />
                    </Form.Group>
                  </Box>
                </Form.Group>
              </Box>
            </div>
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group>
                  <Form.Label className={classes.label}>
                    Show Rank Till
                  </Form.Label>
                  <Box sx={{ display: "flex" }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Select
                        className={classes.input}
                        name="showRankTill"
                        onChange={handleChange}
                        disabled={eventItemRules.showH1L1Bid !== "YES"}
                        value={eventItemRules.showRankTill}
                      >
                        {[
                          [
                            { key: "Show Till Rank 1", value: "SHOW_TILL_1" },
                            { key: "Show Till Rank 2", value: "SHOW_TILL_2" },
                            { key: "Show Till Rank 3", value: "SHOW_TILL_3" },
                            {
                              key: "Show Till Last Rank",
                              value: "SHOW_TILL_LAST",
                            },
                          ].map((item) => (
                            <option value={item.value}>{item.key}</option>
                          )),
                        ]}
                      </Form.Select>
                    </Form.Group>
                  </Box>
                </Form.Group>
              </Box>
            </div>
          </div>
          <div className={classes.formHead}>
            <h5 className="market">Auction Status</h5>
          </div>
          <div className="col">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Select
                    aria-label="Default select example"
                    className={classes.input}
                    name="auctionStatus"
                    onChange={handleChange}
                    value={eventItemRules.auctionStatus}
                  >
                    <option disabled>Auction Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Box>
            </div>
          </div>

          <div className={classes.formHead}>
            <h5 className="message">Message</h5>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Box className={classes.forms}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Event Message
                  </Form.Label>
                  <Form.Control
                    className={classes.input}
                    type="text"
                    name="eventMessage"
                    placeholder="Event Message"
                    onChange={handleChange}
                    value={eventItemRules.eventMessage}
                  />
                </Form.Group>
              </Box>
            </div>
          </div>
        </Box>
        <Box className={classes.saveNextBtn}>
          <Button
            className="next"
            sx={{
              marginBottom: { sm: "1em" },
              marginTop: "1em",
              marginRight: "1em",
            }}
            type="submit"
          >
            Save & Next
          </Button>
          <Button
            onClick={() => {
              navigate(routes.dashboard);
            }}
            className={classes.next}
            sx={{
              marginLeft: "1em",
              marginBottom: "1em !important",
              marginLeft: { sm: "0em" },
              marginTop: { sm: "1em" },
            }}
          >
            Go To Dashboard
          </Button>
        </Box>
      </form>
    </div>
  );
}

export default Rules;
